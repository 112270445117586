<template>
  <div>
    <div id="editor-overlay" ref="editorOverlay">
      <div id="annotations-editor">
        <div id="top-bar">
          <h5>{{ title }}</h5>
          <div>
            <button v-if="readOnly" type="button" class="btn btn-success" v-on:click="onCancel">Close</button>
            <button v-if="!readOnly" type="button" class="btn btn-danger" v-on:click="onCancel">Cancel</button>
            <button v-if="!readOnly" type="button" class="btn btn-primary" v-on:click="onClose">Save Annotations</button>
          </div>
        </div>
        <annotations-control
          id="annotations-control"
          :photoFieldValue="editedPhotoFieldValue"
          :readOnly="readOnly"
          :notifyBalloonChange="notifyBalloonChange"
          @getBalloonValue="val => $emit('getBalloonValue', val)"
          @balloonValueChanged="val => balloonValueChanged(val)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import annotations_control from './annotations_control'

export default {

  components: {
    annotationsControl: annotations_control,
  },
  props: {
    photoFieldValue: Object,
    readOnly: Boolean,
    notifyBalloonChange: Object,
  },
  data: function() {
    return {
      loadedPhotoFieldValue: null,
      editedPhotoFieldValue: null,
      balloonChanges: [],
      cancelling: false,
    }
  },
  watch: {
    photoFieldValue: function(val) {
      if (this.loadedPhotoFieldValue) {
      } else {
        this.editedPhotoFieldValue = _.cloneDeep(val);
        this.loadedPhotoFieldValue = _.cloneDeep(val);
      }
    },
    notifyBalloonChange: function () {
      if (this.cancelling) {
        this.rollback();
      }
    },
  },
  computed: {
    title: function() {
      return this.readOnly ? "Annotations" : "Edit Annotations";
    }
  },
  mounted() {
    this.$refs.editorOverlay.onselectstart = () => {return false};
  },
  methods: {
    balloonValueChanged: function(val) {
      let oldVal = val.payload.oldValue;
      delete val.payload.oldValue;
      if (!_.isNil(oldVal) && !_.find(this.balloonChanges, (c) => c.destination == val.destination)) {
        let rollback = _.cloneDeep(val);
        rollback.payload.value = oldVal;
        this.balloonChanges.push(rollback);
      }
      this.$emit('balloonValueChanged', val);
    },
    onCancel: function() {
      if (!this.isDirty() || window.confirm("Discard annotation edits?")) {
        this.cancelling = true;
        this.rollback();
      }
    },
    onClose: function () {
      let result = this.isDirty() ? this.editedPhotoFieldValue : null;
      this.balloonChanges.length = 0;
      this.$emit("close", result);
    },
    isDirty() {
      return !_.isEqual(this.editedPhotoFieldValue, this.loadedPhotoFieldValue)
    },
    rollback: function() {
      // roll each change back one at a time, waiting for the callback to complete before starting the next
      if (this.balloonChanges.length > 0) {
        let item = this.balloonChanges.pop()
        this.$emit('balloonValueChanged', item);
      } else {
        this.$emit("close", null);
      }
    },
  }
}
</script>

<style scoped>
  #editor-overlay {
    --border-radius: 6px;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #annotations-editor {
    cursor: auto;
    --content-padding: 8px;
    padding: var(--content-padding);
    --top-bar-height: 30px;
    max-width: calc(100vw - 40px);
    max-height: calc(100vh - 40px);
    height: 100%;
    background-color: rgba(255,255,255,1);
    border-radius: var(--border-radius);
  }
  #top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--top-bar-height);
    padding-left: 10px;
  }
  #annotations-control {
    height: 100%;
    max-height: calc(100% - var(--top-bar-height) - var(--content-padding));
  }
</style>