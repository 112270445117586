<template>
  <div>
    <div ref="annotationViewContainer">
      <div id="annotation-view-content" ref="annotationViewContent">
        <img ref="image" class="display-layer"/>
        <annotation-canvas
          :width="width"
          :height="height"
          :annotations="annotations"
          :currentAnnotation="currentAnnotation"
          :annotationDrawingOptions="annotationDrawingOptions"
          :selectPointsWhenCurrentAnnotationChanges="true"
          :readOnly="readOnly"
          @annotationSelected="val => $emit('annotationSelected', val)"
          class="display-layer"
        />
      </div>
    </div>
    <div ref="scalerContainer" class="scaler">
      <img ref="scaler"/>
    </div>
  </div>
</template>

<script>
import annotation_canvas from "./annotation_canvas.vue"
import { DrawingOptions } from "../../lib/annotations/annotation"

const ZOOM_MIN_STOP = 1 // lowest value on slider
const ZOOM_MAX_STOP = 10 // highest value on slider
const IMAGE_MAX_AREA = 3145728 //2148 * 1536 -> this is the max size the view image will be on the app

export default {
  components: {
    annotationCanvas: annotation_canvas,
  },
  props: {
    annotations: Array,
    currentAnnotation: Object,
    annotationDrawingOptions: DrawingOptions,
    background: Image,
    readOnly: Boolean,
    zoom: Number,
    zoomExtension: Number,
  },
  data: function () {
    return {
      annotationViewContainer: null,
      annotationViewContent: null,
      scalerContainer: null,
      scaler: null,
      image: null,
      width: 0,
      height: 0,
      zoomFactor: 1,
      relativeScroll_x: 0.5,
      relativeScroll_y: 0.5,
      scrollbarWidth: 17,
    }
  },
  watch: {
    background: function(image) {
      if (image != null) {
        this.scaler.src = image.src;
        this.image.src = image.src;

        // set max canvas height for text size calculation
        this.annotationDrawingOptions.backgroundNaturalHeight = image.naturalHeight;

        this.resize();
      }
    },
    zoom: function() {
      this.changeZoom();
    },
    zoomExtension: function() {
      this.changeZoom();
    },
  },
  computed: {
    externalWidth: function() {
      return this.width + this.scrollbarWidth;
    },
    externalHeight: function() {
      return this.height + this.scrollbarWidth;
    },
  },
  mounted() {
    this.annotationViewContainer = this.$refs.annotationViewContainer;
    this.annotationViewContent = this.$refs.annotationViewContent;
    this.scalerContainer = this.$refs.scalerContainer;
    this.scaler = this.$refs.scaler;
    this.image = this.$refs.image;
    window.addEventListener('resize', this.resize);
    this.annotationViewContent.addEventListener('scroll', this.onScroll);
  },
  beforeUnmount: function() {
    window.removeEventListener('resize', this.resize);
    this.annotationViewContent.removeEventListener('scroll', this.onScroll);
  },
  methods: {
    changeZoom: function() {
      let defaultZoom = 1;
      let zoomRange = this.zoomExtension - defaultZoom;
      let stopCount = ZOOM_MAX_STOP - ZOOM_MIN_STOP;
      let result = zoomRange * (this.zoom - ZOOM_MIN_STOP) / stopCount + defaultZoom;
      this.zoomFactor = result;
      this.resize();
    },
    resize: function() {
      let scalerContainer = this.scalerContainer;
      let view = this.annotationViewContainer;
      scalerContainer.classList.remove('display-none');
      view.classList.add('display-none');
      let rect = scalerContainer.getBoundingClientRect();
      view.classList.remove('display-none')
      scalerContainer.classList.add('display-none')
      if (rect.width > 0 && rect.height > 0) {
        view.style['width'] = rect.width + 'px';
        view.style['height'] = rect.height + 8 + 'px';
        
        // TODO: sort out scrollbar dimensions

        let imageRect = this.annotationViewContainer.getBoundingClientRect();
        let width = Math.trunc(imageRect.width * this.zoomFactor) - this.scrollbarWidth;
        let height = Math.trunc(imageRect.height * this.zoomFactor) - this.scrollbarWidth;

        if (this.width != width || this.height != height) {
          this.image.width = width;
          this.image.height = height;
          this.width = this.image.width;
          this.height = this.image.height;
        }
        this.reCentreFocalPoint();
      }
    },
    onScroll: function () {
      let rect = this.annotationViewContent.getBoundingClientRect();
      this.relativeScroll_x = (this.annotationViewContent.scrollLeft + rect.width / 2) / this.width;
      this.relativeScroll_y = (this.annotationViewContent.scrollTop + rect.height / 2) / this.height;
      this.viewportChanged();
    },
    reCentreFocalPoint: function (panTo) {
      let rect = this.annotationViewContent.getBoundingClientRect();
      let top = this.height * this.relativeScroll_y - rect.height / 2;
      let left = this.width * this.relativeScroll_x - rect.width / 2;
      this.annotationViewContent.scrollTo({
        top: top,
        left: left,
        behavior: (panTo ? 'smooth' : 'auto')
      });
      this.viewportChanged();
    },
    viewportChanged: function() {
      let rect = this.annotationViewContent.getBoundingClientRect();
      let top = (this.annotationViewContent.scrollTop);
      let left = (this.annotationViewContent.scrollLeft);
      let bottom = (this.annotationViewContent.scrollTop + rect.height);
      let right = (this.annotationViewContent.scrollLeft + rect.width);
      let relativeViewport = {
        top: Math.max(0, top / this.externalHeight),
        left: Math.max(0, left / this.externalWidth),
        bottom: Math.min(1, bottom / this.externalHeight),
        right: Math.min(1, right / this.externalWidth),
        aspectRatio: (right - left) / (bottom - top)
      };
      this.$emit('relativeViewportChanged', relativeViewport);
    },
  }
}
</script>

<style scoped>
  #annotation-view-content {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: scroll;
  }
  .display-layer {
    grid-area: 1 / 1 / 1 / 1;
    max-width: none;
  }
  .scaler {
    max-width: 100%;
    max-height: 100%;
    visibility: hidden;
    overflow: hidden;
  }
  .scaler img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
  }

  .display-none {
    display: none;
  }

</style>