<template>
  <div class="inspection-photo" v-show="isVisible">
    <label v-bind:for="formId" class="control-label">{{ label }}<span v-if="isRequired">*</span></label>
      <p class="help-block" v-if="hasHelpText">{{ helpText }}</p>
      <p class="criteria-block" v-if="showCriteria">{{ criteriaText }}</p>
      <p class="criteria-block" v-if="showMandatoryText">This field is mandatory.</p>
    <div class="zoom-button-container">
      <button v-show="showZoom" :disabled="!zoomedOut" class="btn-primary btn-small zoom-button" @click.prevent="zoomOut" title="Zoom Out"><i class="icon-minus icon-white"></i></button>
      <button v-show="showZoom" :disabled="!zoomedIn" class="btn-primary btn-small zoom-button" @click.prevent="zoomIn" title="Zoom In"><i class="icon-plus icon-white"></i></button>
    </div>
    <div class="photo-container" ref="photoContainer" v-on:scroll="onScrollPhoto">
      <dropzone class="file-drop-zone" 
        v-if="!isReadOnly"
        v-show="!hasPhoto && sourceFile" 
        label="Drop your image file here (jpg, jpeg format)" 
        @files_selected="val => onFilesDragged(val)"/>
      <div class="scaled-image" ref="scaledImage">
        <img class="photo" v-if="hasPhoto" :src="photoUrl" ref="photoField" v-on:load="onPhotoLoad">
        <img class="annotation" v-if="hasAnnotation" :src="annotationUrl" ref="annoField">
      </div>
    </div>
    <div class="image-control-container">
      <div class="alert alert-error small-alert" v-if="showFileUploadSelectionError">Select a valid file (jpg or jpeg)... come on ...</div>
      <div class="alert alert-error small-alert" v-if="showFileSizeError">Please check your file, it needs to be less than 20MB...</div>
      <div class="alert alert-error small-alert" v-if="showImageAreaError">Please check your file, it needs to be less than 40 Megapixels...</div>
      <div class="alert alert-error small-alert" v-if="showFileUploadError">{{ uploadErrorText }}</div>
      <div class="alert alert-error small-alert" v-if="annotationError">
        {{ annotationError }}
        <button class="pull-right" @click.prevent="onRegenerateOverlay">Retry</button>
      </div>
      <div class="alert alert-success small-alert" v-if="showLoadInProgress">{{ loadingProgress }}</div>
      <div class="alert alert-success small-alert" v-if="showAssetCreatedSuccessfully">Asset created successfully!</div>
      <input style="display: none" type="file" accept="image/jpeg" @change="onFileChosen" ref="fileInput" >
      <button v-if="!isReadOnly" v-show="sourceFile" @click.prevent="clickFileInput" class="btn btn-small">Load Image File...</button>
      <button v-show="hasPhoto" @click.prevent="openEditor" class="btn btn-primary btn-small">Annotations</button>
      <button v-if="!isReadOnly && hasPhoto" v-show="!isReadOnly" class="btn btn-danger btn-small pull-right" @click.prevent="removeImage">Remove Image</button>
      <select v-if="!isReadOnly && hasDrawingSets && sourceDrawing" :disabled="isReadOnly" v-model="selectedDrawing" class="span4 drawing-select" ref="seldrawing">
        <option value="TOP" disabled selected>Choose from Drawing Sets ...</option>
        <optgroup :label="drawingSet.drawing_set_name" v-for="drawingSet in filteredDrawingSets" :key="drawingSet.drawing_set_name">
          <option :value="option.key" v-for="option in drawingsOptions(drawingSet)" :key="option.key">{{ option.value }}</option>
        </optgroup>
      </select>
    </div>
    <annotation-editor
      :photoFieldValue="fieldValue"
      :readOnly="isReadOnly"
      :notifyBalloonChange="notifyFieldData"
      v-if="showEditor"
      @getBalloonValue="val => notifyFieldEvent(val)"
      @balloonValueChanged="val => notifyFieldEvent(val)"
      @close="val => closeEditor(val)"
    />
  </div>
</template>

<script>
  import _ from 'lodash'
  import fieldMixin from '../../mixins/field_mixin'
  import fieldLabelMixin from '../../mixins/field_label_mixin'
  import fieldVisibilityMixin from '../../mixins/field_visibility_mixin'
  import fieldValidationMixin from '../../mixins/field_validation_mixin'
  import { EventBus } from '../../lib/event_bus'
  import generateUUID from 'uuid/v4'
  import { AssetUploader } from "../../lib/asset_uploader"
  import dropzone from '../utils/dropzone.vue'

  import annotation_editor from '../annotation_editor/annotation_editor.vue'
  import { OverlayGenerator } from '../../lib/annotations/annotation_overlay_generator.js'

  const OVERLAY_ERROR_TEXT = 'Failed to gnerate an annotation overlay.';

  export default {
    components: {
      dropzone,
      annotationEditor: annotation_editor,
    },
    mixins: [fieldMixin, fieldLabelMixin, fieldVisibilityMixin, fieldValidationMixin],
    data: function () {

      let initialSelection = null;
      let initialDrawingSets = this.applyRestriction(this.drawingSets);
      let initialWindow = (!!this.data && !!this.data.window) ? this.data.window : { "tl_x": 0,"tl_y": 0, "scale_side": 1 };

      if (!_.isNil(this.data)) {
        initialSelection = _.isNil(this.data.drawing_id) ? 'TOP' : this.data.drawing_id;
      } else {
        initialSelection = 'TOP'
      }
      let overlayGenerator = OverlayGenerator(this.onNewOverlaySuccess, this.onNewOverlayFail);

      return {
        selectedDrawing: initialSelection,
        selectedFile: null,
        loadInProgress: false,
        fileLoadProgress: "",
        createdFileSuccess: false,
        errorText: "Oops, something we weren't expecting went wrong!",
        fileUploadError: false,
        annotationError: null,
        imageArea: 0,
        imageMaxArea: 40000000,
        fileMaxSize: 20000000,
        originalImgWidth: 600,
        originalImgHeight: 400,
        viewWindow: initialWindow,
        defaultWindow: { "tl_x": 0,"tl_y": 0, "scale_side": 1 },
        scrollOffset: 15,
        zoomStep: 0.1,
        zoomMax: 0.1,
        roundDP: 6,
        scrollChangedBy: "",
        showEditor: false,
        fieldValue: null,
        newFieldValue: null,
        changingImage: false,
        overlayGenerator: overlayGenerator,
        newAnnotationAssetUUID: generateUUID(),
        editCounter: 0,
        filteredDrawingSets: initialDrawingSets
      }
    },
    watch: {
      selectedDrawing: function (newSelection) {
        if (!this.setSelection(newSelection)) {
          if (!_.isNil(this.data) && !_.isNil(this.data.drawing_id)) {
            this.selectedDrawing = this.data.drawing_id
          } else {
            this.selectedDrawing = 'TOP';
          };
        } 
      },
      viewWindow: function (newWindow) {
        this.setNewWindow(newWindow);
      },
      zoomLevel: function () {
        if (this.hasPhoto) { this.setZoomWindow()};
      },
      notifyFieldData: function(val) {
        if (!this.showEditor) {
          if (val.type == 'updatedBalloonText') {
            _.forEach(this.data.annotations, (a) => {
              let sections = a.linked_feature.split('/');
              let last = sections[sections.length - 1];
              if (last == val.source
                  && a.annotation_type == 'balloon') {
                a.annotation_data.text = val.payload.newValue;
                a.annotation_data.full_text = val.payload.fullValue;
                this.onRegenerateOverlay();
              }
            })
          }
        }
      },
    },
    mounted() {
      this.scanForLinks();
    },
    computed: {
      sourceFile: function () {
        return (this.photoSourceFor('folder') || this.photoSourceFor('camera'));
      },
      sourceDrawing: function () {
        return this.photoSourceFor('drawing');
      },
      hasPhotoSource: function () {
        return !_.isEmpty(this.specification['source'])
      },
      zoomLevel: function () {
        return this.hasWindow ? this.data.window.scale_side : this.defaultWindow.scale_side ;
      },
      windowPosition: function () {
        return this.hasWindow ? [this.data.window.tl_x, this.data.window.tl_y] : [this.defaultWindow.tl_x, this.defaultWindow.tl_y] ;
      },
      isPresent: function () {
        return this.hasPhoto;
      },
      showZoom: function () {
        return !this.isReadOnly && this.hasPhoto;
      },
      hasWindow: function () {
        return !!this.data && !!this.data.window;
      },
      hasValidWindow: function () {
        return (this.hasWindow && !_.isUndefined(this.data.window.scale_side) && !_.isUndefined(this.data.window.tl_x) && !_.isUndefined(this.data.window.tl_y)) 
      },
      hasDrawingSets: function () {
        return !!this.filteredDrawingSets && !_.isEmpty(this.filteredDrawingSets)
      },
      hasPhoto: function () {
        return !!this.data && !!this.data.asset_id && this.data.asset_id !== '';
      },
      usesDrawing: function () {
        return !!this.data && !!this.data.drawing_id && this.data.drawing_id !== '';
      },
      hasAnnotation: function () {
        let trigger = this.editCounter;  // we want an update every time we reset the url
        return !!this.data && !!this.data.annotation_asset_id && this.data.annotation_asset_id !== '';
      },
      photoUrl: function () {
        return this.assetUrl(this.data.asset_id)
      },
      annotationUrl: function() {
        let queryAddition = '?' + this.editCounter;
        return !this.data || !this.data.annotation_asset_id ? '' : this.assetUrl(this.data.annotation_asset_id) + queryAddition;
      },
      uploadErrorText: function () {
        return this.errorText
      },
      loadingProgress: function () {
        return this.fileLoadProgress
      },
      hasValidFileType: function () {
        // check that file is a jpg
        return this.requiredFileType(this.selectedFile)
      },
      hasValidFileSize: function () {
        // check size of chosen file
        return !!this.selectedFile && (this.selectedFile.size < this.fileMaxSize);
      },
      hasValidImageArea: function () {
        return (this.imageArea < this.imageMaxArea);
      },
      showFileUploadSelectionError: function () {
        return !!this.selectedFile && !this.hasValidFileType;
      },
      showFileSizeError: function () {
        return !!this.selectedFile && !this.hasValidFileSize;
      },
      showImageAreaError: function () {
        return !!this.selectedFile && !this.hasValidImageArea;
      },
      showAssetCreatedSuccessfully: function () {
        return this.createdFileSuccess
      },
      showFileUploadError: function () {
        return this.fileUploadError
      },
      showLoadInProgress: function () {
        return this.loadInProgress
      },
    },
    methods: {
      applyRestriction: function (drawingSets) {
        let vm = this;
        if (_.isUndefined(vm.specification.restrict_to_categories) || _.isEmpty(vm.specification.restrict_to_categories)) {
          return drawingSets;
        } else {
          return _.filter(drawingSets, function (drawingSet) {
            return _.includes(vm.specification.restrict_to_categories, drawingSet.drawing_set_category)
          })          
        }
      },
      openEditor: function() {
        this.fieldValue = null;
        this.showEditor = true;
        this.$nextTick(() => this.fieldValue = this.data);
        this.annotationError = null;
      },
      closeEditor: function(newField) {
        this.showEditor = false;
        if (newField && !this.isReadOnly) {
          this.emitUpdateData(newField);
          if (!!newField.annotations && newField.annotations.length > 0) {
            this.newFieldValue = newField;
            this.generateOverlay();
          }
        }
      },
      scanForLinks: function() {
        let links = [];
        if (this.data && this.data.annotations) {
          _.forEach(this.data.annotations, (a) => {
            if (!!a.linked_feature) {
              // since the inspection_section works in relative paths - we need to give it just the last section to avoid a misunderstanding
              let sections = a.linked_feature.split('/');
              let last = sections[sections.length - 1];
              links.push(last);
            }
          })
        }
        let reg = {
          owner: this.specification.reference,
          links: links

        };
        this.$emit('registerLinks', reg);
      },
      notifyFieldEvent: function(val) {
        val.source = this.specification.reference;
        this.$emit('notifyFieldEvent', val);
      },
      onScrollPhoto: _.debounce(function(e) {
        if (this.scrollChangedBy != "SHIFT") {
          if (this.hasPhoto && this.scrollChangedBy != "CALC") { 
            this.calculateXY(e) 
            this.scrollChangedBy = "CALC"
          } else {
            this.scrollChangedBy = ""
          }
        } else {
          this.scrollChangedBy = ""
        }
      }, 400),
      photoSourceFor: function (photo_source) {
        return (this.hasPhotoSource && (_.includes(this.specification['source'],photo_source))) || !this.hasPhotoSource
      },
      checkValidImageAreaAndLoad: function (localFile) {
        // check image is not too large
        var _URL = window.URL || window.webkitURL;
        var img = new Image();
        let vm = this;

        img.onload = function() {
          vm.imageArea = (this.width * this.height);
          if (vm.hasValidFileType && vm.hasValidFileSize && vm.hasValidImageArea) {
            vm.onUpload();
          };
        };
        img.src = _URL.createObjectURL(localFile);
      },
      clearImageErrorState: function () {
          this.selectedFile = null;
          this.imageArea = 0;
      },
      clickFileInput: function () {
        this.clearImageErrorState();
        this.$refs.fileInput.click();          
      },
      zoomedIn: function () {
        return (this.zoomLevel == this.zoomMax);
      },
      zoomedOut: function () {
        return (this.zoomLevel == 1);
      },
      zoomIn: function () {
        if (!this.zoomedIn()) {
          this.zoomImage(+1);
        }
      },
      zoomOut: function () {
        if (!this.zoomedOut()) {
          this.zoomImage(-1);
        }
      },
      zoomImage: function (direction) {
        // direciton = +1 (in) or -1 (out)
        let newWindow = this.getClone();
        let sideScale = newWindow['scale_side'];

        sideScale = _.round(sideScale - (direction * this.zoomStep), this.roundDP);
        newWindow.tl_x = _.round(newWindow.tl_x + (direction * (this.zoomStep / 2)), this.roundDP);
        newWindow.tl_y = _.round(newWindow.tl_y + (direction * (this.zoomStep / 2)), this.roundDP);

        newWindow.scale_side = sideScale;
        
        this.updateWindowData(this.nudgeVisibleWindow(newWindow));
      },
      calculateXY: function (scrollEvent) {
        let newWindow = this.getClone();

        let XPos = scrollEvent.target.scrollLeft;
        let YPos = scrollEvent.target.scrollTop;
        newWindow.tl_x = _.round(XPos / scrollEvent.target.scrollWidth, this.roundDP);
        newWindow.tl_y = _.round(YPos / scrollEvent.target.scrollHeight, this.roundDP);

        this.updateWindowData(this.nudgeVisibleWindow(newWindow));
      },
      getClone: function () {
        var window = this.data.window ? this.data.window : this.defaultWindow;
        return _.cloneDeep(window);
      },
      nudgeVisibleWindow: function (thisWindow) {
        // Used after scrolling to tweak XY - assumes sideScale is <1
        // take the proposed window and nudge offset based on dimensions and x, y so it fits in 1, 1 square
        const rounding_error = 0.000001;
        let newWindow = {};
        if (_.isEmpty(thisWindow)) {
          newWindow = _.cloneDeep(this.defaultWindow);
        } else {
          newWindow = _.cloneDeep(thisWindow);
        }
        let sideScale = newWindow.scale_side;
        let tl_x = newWindow.tl_x;
        let tl_y = newWindow.tl_y;

        if (sideScale > 1) { sideScale = 1 };
        if (sideScale < this.zoomMax) { sideScale = this.zoomMax };
        if (tl_x < 0) { tl_x = 0};
        if (tl_y < 0) { tl_y = 0};
        if ((tl_x + sideScale) > 1) { tl_x = _.round(1 - sideScale, this.roundDP)};
        if ((tl_y + sideScale) > 1) { tl_y = _.round(1 - sideScale, this.roundDP)};

        newWindow.scale_side = sideScale;
        newWindow.tl_x = tl_x;
        newWindow.tl_y = tl_y;

        return newWindow;
      },
      setNewWindow: function (newWindow) {
        // scale and shift the image
        newWindow = this.nudgeVisibleWindow(newWindow);
        // windows have same aspect ratio as original image
        var zoomScale = 1 / newWindow.scale_side;
        var newImgWidth = this.originalImgWidth * zoomScale; 
        var newImgHeight = this.originalImgHeight * zoomScale; 
        var XScroll = _.round(newWindow.tl_x * newImgWidth);
        var YScroll = _.round(newWindow.tl_y * newImgHeight);

        var newLeft = (-1 * XScroll);
        var newTop = (-1 * YScroll);

        // set container to these dimensions
        let photoContainer = this.$refs.photoContainer;

        this.setPhotoAttributes(newImgWidth);

        if (!this.showZoom) {
          this.setPhotoPosition(newLeft, newTop)
        } else {
          photoContainer.scrollTo(XScroll, YScroll);
        }
      },
      setPhotoAttributes: function (newImgWidth) {
        let photoField = this.$refs.photoField;
        let annoField = this.$refs.annoField;

        if(!_.isUndefined(photoField)) {
          photoField.style["max-width"] = "none";
          photoField.style["width"] = newImgWidth + "px";
          photoField.style["position"] = "absolute";
          if (this.hasAnnotation) {
            annoField.style["max-width"] = "none";
            annoField.style["width"] = newImgWidth + "px";
            annoField.style["position"] = "absolute";
          }          
        }
      },
      setPhotoPosition: function (new_x, new_y) {
        let photoField = this.$refs.photoField;
        let annoField = this.$refs.annoField;

        if(!_.isUndefined(photoField)) {
          photoField.style["left"] = new_x + "px";
          photoField.style["top"] = new_y + "px";
          if (this.hasAnnotation) {
            annoField.style["left"] = new_x + "px";
            annoField.style["top"] = new_y + "px";
          }
        }
      },
      setWindowPosition: function () {
        // shift the image
        let newWindow = this.nudgeVisibleWindow(this.data.window);
        // windows have same aspect ratio as original image
        var zoomScale = 1 / newWindow.scale_side;
        var newImgWidth = this.originalImgWidth * zoomScale; 
        var newImgHeight = this.originalImgHeight * zoomScale; 
        var XScroll = _.round(newWindow.tl_x * newImgWidth);
        var YScroll = _.round(newWindow.tl_y * newImgHeight);

        // set container to these dimensions
        let photoContainer = this.$refs.photoContainer;

        this.setPhotoAttributes(newImgWidth);

        photoContainer.scrollTo(XScroll, YScroll);
      },
      setZoomWindow: function () {
        // scale 
        let newWindow = this.nudgeVisibleWindow(this.data.window);
        // windows have same aspect ratio as original image
        var zoomScale = 1 / newWindow.scale_side;
        var newImgWidth = this.originalImgWidth * zoomScale; 
        var newImgHeight = this.originalImgHeight * zoomScale; 
        // set container to these dimensions
        let photoContainer = this.$refs.photoContainer;

        this.setPhotoAttributes(newImgWidth);
      },      
      onPhotoLoad: function (event) {
        if (this.changingImage) {
          this.generateOverlay();
          this.changingImage = false;
        }
        let photoField = this.$refs.photoField;
        let photoContainer = this.$refs.photoContainer;

        this.originalImgWidth = 620 ; //photoField.width; 
        this.originalImgHeight = (photoField.height / photoField.width) * this.originalImgWidth
        
        if (this.showZoom) { this.$refs.photoContainer.style["overflow"] = "scroll"; }

        this.scrollOffset = (photoContainer.offsetHeight - photoContainer.clientHeight)
        photoContainer.style["height"] = this.originalImgHeight + this.scrollOffset + "px";
        photoContainer.style["width"] = this.originalImgWidth + this.scrollOffset + "px";

        this.setPhotoPosition(0, 0);
        this.viewWindow = this.nudgeVisibleWindow(this.data.window);
      },
      assetIdForDrawingId: function(drawingId) {
        // return asset_id for chosen drawing
        let vm = this;
        let result = _.find(
          _.flatten(_.map(vm.filteredDrawingSets, function(thisSet) {
            return thisSet.drawings
            })),function (o) { 
            return o.drawing_id == drawingId 
            }
          );
        return result.view_asset_id;
      },
      setSelection: function (selectedDrawingId) {
        // have they chosen a drawing
        if (!_.isNil(this.data) && !_.isNil(this.data.drawing_id) && (selectedDrawingId == this.data.drawing_id)) { 
          return false 
        };

        if (!_.isEmpty(selectedDrawingId) && selectedDrawingId != 'TOP') {
          this.changeAsset(this.assetIdForDrawingId(selectedDrawingId), selectedDrawingId)
        } 
        return true;
      },      
      emptyObject: function () {
        return [{ key: "TOP", value: "Choose from Drawing Sets ..." }];
      },
      drawingSetObject: function (drawingSet) {
        return {key: "", value: drawingSet.drawing_set_name};
      },
      drawingsOptions: function (drawingSet) {
        let fullOptions = _.map(drawingSet.drawings, function (drawing) {
          return {key: drawing.drawing_id, value: drawing.drawing_name}
        });
        return fullOptions ;
      },
      assetUrl: function (assetId) {
        return '/o/' + this.organisationId() + '/assets/' + (assetId)
      },
      organisationId: function () {
        const organisationIdRegex = /^\/o\/(\d+)\//g;
        let matches = organisationIdRegex.exec(window.location.pathname)

        if (matches) {
          return matches[1]
        }
      },
      requiredFileType: function (file){
        let checkType = ((file != null) && (file.type == 'image/jpeg'));
        return checkType;
      },
      toggleLoadInProgress: function () {
        this.loadInProgress = !this.loadInProgress;
        if (!(this.loadInProgress)) {
          this.fileLoadProgress = '';
        }
        else{
          this.resetAssetCreatedSuccess()
        }
      },
      toggleCreateSuccess: function () {
        this.createdFileSuccess = !this.createdFileSuccess;
        if (this.createdFileSuccess) {
          this.selectedFile = null;
        }
      },
      resetAssetCreatedSuccess: function () {
        this.createdFileSuccess = false
      },
      toggleLoadSuccessStatus: function () {
        this.fileUploadError = !this.fileUploadError
      },
      onFileChosen: function (event) {
        this.onFileSelected(event.target.files[0]);
      },
      onFilesDragged: function (files) {
        this.onFileSelected(files[0]);
      },
      onFileSelected: function (file) {
        this.selectedFile = file;
        if (this.hasValidFileType && this.hasValidFileSize) {
          this.checkValidImageAreaAndLoad(this.selectedFile);
        }
      },
      removeImage: function () {
        if (this.hasPhoto && window.confirm("Are you sure you want to remove image: " + this.label + " and any annotations?")) {
          if (this.usesDrawing && this.hasDrawingSets) {
            this.selectedDrawing = 'TOP';
          }
          let photoContainer = this.$refs.photoContainer;
          photoContainer.style["height"] = "";
          photoContainer.style["overflow"] = "hidden";
          this.clearImageErrorState();
          this.emitClearData();
        }
      },
      onUpload: function () {
        let vm = this;

        if (this.hasValidFileType) {

          vm.fileUploadError = false;
          vm.toggleLoadInProgress();

          let uploader = AssetUploader(
            success, 
            fail, 
            message => vm.fileLoadProgress = message
          );

          uploader.uploadJpeg(vm.selectedFile)
        }
        function success(assetId) {
          vm.toggleCreateSuccess();
          vm.toggleLoadInProgress();

          setTimeout( () => {
            vm.selectedDrawing = 'TOP';
            vm.changeAsset(assetId, null);
          },2000);
          setTimeout( () => {
            vm.toggleCreateSuccess();
            vm.$refs.fileInput.value="";
            vm.currentValidity = vm.assessValidity();
          },3500);
        };
        function fail(message) {
          vm.errorText = message;
          vm.toggleLoadInProgress();
          vm.toggleLoadSuccessStatus();
        }
      },
      changeAsset: function(assetId, drawingId) {
        this.annotationError = null;
        let newPhotoField = {
          type: 'photo',
          asset_id: assetId,
          window: _.cloneDeep(this.defaultWindow),
        };
        if (drawingId) {
          newPhotoField.drawing_id = drawingId;
        }
        if (this.data && this.data.annotations && this.data.annotations.length > 0
            && window.confirm("Would you like to keep the existing annotations?")) {
          newPhotoField.annotations = _.cloneDeep(this.data.annotations);
          if (this.data && this.data.text_scale) {
            newPhotoField.text_scale = this.data.text_scale;
          }
          this.newFieldValue = newPhotoField;
          this.changingImage = true;
        }

        let dataToSend = _.cloneDeep(newPhotoField);
        let hasAnnotations = !!dataToSend.annotations && dataToSend.annotations.length > 0;
        if (this.data && hasAnnotations && this.data.annotation_asset_id) {
          dataToSend.annotation_asset_id = this.data.annotation_asset_id;
        }
        this.emitUpdateData(dataToSend);
        this.currentValidity = this.assessValidity();
      },
      updateWindowData: function (newWindow) {
        let dataToSend = _.cloneDeep(this.data);
        dataToSend.window = newWindow;
        this.viewWindow = newWindow;
        this.emitUpdateData(dataToSend);
      },
      emitUpdateData: function (dataToSend) {
        this.$refs.fileInput.className="dirty";
        this.$emit('update:data', dataToSend);
      },
      emitClearData: function () {
        this.annotationError = null;
        this.$refs.fileInput.className="dirty";
        this.$emit('clear:data');
      },
      generateOverlay: function() {
        if (this.newFieldValue && this.newFieldValue.annotations) {
          this.fieldValue = this.newFieldValue;
          this.overlayGenerator.start(this.newFieldValue, this.$refs.photoField, this.newAnnotationAssetUUID);
        }
      },
      onNewOverlaySuccess: function (annotation_asset_id) {
        this.fieldValue.annotation_asset_id = annotation_asset_id;
        this.emitUpdateData(this.fieldValue);
        this.resetOverlayGenerationFunction();
        this.annotationError = null;
      },
      onNewOverlayFail: function (message) {
        this.annotationError = OVERLAY_ERROR_TEXT;
        this.resetOverlayGenerationFunction();
      },
      onRegenerateOverlay: function() {
        this.newFieldValue = this.data;
        this.generateOverlay();
      },
      resetOverlayGenerationFunction: function() {
        this.newFieldValue = null;
        this.$nextTick(() => {
          this.editCounter += 1;
        });
      },
    },
    created: function () {
      let vm = this;
      if (!!vm.underRollup){
        EventBus.$on(vm.underRollup, (eventData) => {
          vm.visibleUnderRollup = eventData['rollup'];
          let thisShowHide = eventData['groupShowHide'];
          if (!!thisShowHide) {
            vm.groupShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
      if (!!vm.controlledField){
        EventBus.$on(vm.controlEvent, (ShowHideData) => {
          let thisShowHide = ShowHideData[vm.reference];
          if (!!thisShowHide) {
            vm.controlShowHide = thisShowHide;
            vm.assessValidity();
          }
        });
      };
    }
  }
</script>

<style scoped lang="scss">
  .photo-container {
    position: relative;
    width: 620px;
    margin-bottom: 10px;
    overflow: clip;
  }

  .photo {
    width: 620px;
  }

  .file-drop-zone {
    height: 80px;
  }

  .image-control-container {
    position: relative;
    width: 620px;
  }

  .inspection-photo .annotation {
    position: absolute;
    top: 0;
    left: 0;
  }

  .inspection-photo {
    .photo, .annotation {
      width: 100%;
    }
    & {
      margin-bottom: 20px;
      width: 620px;
    }
  }

  .hide-annotation {
    margin-bottom: 5px;
    float: left;
  }

  .small-alert {
    width: 569px;
    margin-bottom: 5px;
  }

  .drawing-select {
    margin-bottom: 0px;
  }

  .zoom-button-container {
    position: relative;
    width: 620px;    
  }

  .zoom-button {
    float: right;
  }

</style>
