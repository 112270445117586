<template>
  <fieldset v-if="!isRow" class="feature-instance" v-bind:class="{ emphasizenew: newForm }" v-bind:ref="instance.id" v-bind:id="instance.id">
    <legend v-bind:class="{ emphasizenew: newForm }" >
      <a v-on:click="toggle" ><i  :class="['icon', iconClass]" title="Click to toggle"></i></a>
      {{ interpolatedLabel }}
      <button v-show="!readOnly" type="button" class="btn btn-danger btn-small pull-right" v-on:click="remove" :disabled="disableRemove">Remove</button>
    </legend>
    <inspection-section
      :fields="fields"
      :data="instance.data"
      @update:data="data => dataUpdated(data)"
      :read-only="readOnly"
      :new-form="newForm"
      :drawing-sets="drawingSets"
      :under-rollups="rollupCoverage"
      :default-rollup="defaultRolledUp"
      :controlled-fields="controlledFields"
      :show-validation="showValidation"
      :validate-now="validateNow"
      :instanceReference="instanceReference"
      :subFeatureToShow="subFeatureToShow"
      :notifyFieldEvent="notifyFieldEvent"
      @update:errors="newErrors => passthroughErrors(newErrors)"
      v-show="showInstance"
      />
  </fieldset>
  <inspection-section-row
    v-else
    :fields="fields"
    :columns="columns"
    :data="instance.data"
    :fieldsToUse="fieldsToUse"
    @update:data="data => dataUpdated(data)"
    :read-only="readOnly"
    :new-form="newForm"
    :under-rollups="rollupCoverage"
    :default-rollup="defaultRolledUp"
    :controlled-fields="controlledFields"
    :show-validation="showValidation"
    :validate-now="validateNow"
    :instanceReference="instanceReference"
    :notifyFieldEvent="notifyFieldEvent"
    @update:errors="newErrors => passthroughErrors(newErrors)"
    v-show="showInstance"
    />
</template>

<script>
  import _ from 'lodash'
  import labelTemplateMixin from '../../mixins/label_template_mixin'
  import InspectionSection from '../inspection_section.vue'
  import InspectionSectionRow from '../inspection_section_row.vue'
  
  export default {
    beforeCreate: function () {
      // To avoid a recursive loop in component registration register this requirement at runtime
      this.$options.components.InspectionSection = InspectionSection ;
      this.$options.components.InspectionSectionRow = InspectionSectionRow ;
    },
    mounted: function () {
      if (this.newForm && ! this.isRow) {
        this.$refs[this.instance.id].scrollIntoView({ behavior: 'smooth' });
      }
    },
    mixins: [labelTemplateMixin],
    props: {
      specification: {
        type: Object,
        required: true
      },
      fields: Array,
      instance: Object,
      readOnly: {
        type: Boolean,
        default: function () { return false; }
      },
      index: Number,
      show: {
        type: Boolean,
        default: function () {
          return true;
        }
      },
      isRow: Boolean,
      newForm: Boolean,
      columns: Array,
      fieldsToUse: Array,
      showValidation: Boolean,
      validateNow: String,
      instanceReference: String,
      drawingSets: Array,
      subFeatureToShow: String,
      notifyInstanceData: Object,
    },
    data: function () {
      let initialShow = this.show;
      let initialLinkValue = this.getLinkValue(this.instance.data);
      return {
        showInstance: initialShow,
        linkValue: initialLinkValue,
        notifyFieldEvent: null,
      }
    },
    watch: {
      show: function (newValue) {
        this.showInstance = newValue;
      },
      interpolatedPrefix: function() {
        this.calculateBalloonText();
      },
      interpolatedSuffix: function() {
        this.calculateBalloonText();
      },
      linkValue: function() {
        this.calculateBalloonText();
      },
      notifyInstanceData: function(val) {
        if (val.type == 'balloonValueChanged') {
          // we make the assumption that we've landed on the destination feature
          // TODO: adapt to support deepter paths if needed
          val.destination = this.specification.link_field
          this.notifyFieldEvent = val;
        } else if (val.type == 'getBalloonValue') {
          this.calculateBalloonText();
        }
      },
    },
    computed: {
      data: function () {
        return this.instance.data;
      },
      disableRemove: function () {
        // Use comparision to false so undefined and nil don't disallow remove
        return this.isReadOnly || this.specification.allow_remove === false
      },
      labelTemplateStr: function () {
        return this.specification.instance_label
      },
      iconClass: function () {
        return !this.showInstance ? 'icon-chevron-right' : 'icon-chevron-down'
      },
      rollupCoverage: function () {
        // iterate over fields, make corss reference
        let localHash = {};
        let lastRollup = '';
        let vm = this;
        _.forEach(vm.fields, function(thisField) {
          if (!!thisField.rollup) {
            if (thisField.rollup == 'start') {
              lastRollup = vm.instanceReference + "-" + thisField.reference;
            } else {
              lastRollup = '';
            }
          };
          localHash[thisField.reference] = lastRollup;
        })
        return _.cloneDeep(localHash);
      },
      defaultRolledUp: function () {
        let localHash = {};
        let vm = this;
        _.forEach(vm.fields, function(thisField) {
          if (!!thisField.rollup) {
            if (thisField.rollup == 'start') {
              // If there is a style then test it else false
              if (!!thisField.style) {
                localHash[vm.instanceReference + "-" + thisField.reference] = vm.rollupState(thisField.style);
              } else {
                localHash[vm.instanceReference + "-" + thisField.reference] = false;
              }
            }
          }
        })
        return _.cloneDeep(localHash);        
      },
      controlledFields: function () {
        // Check for any select that have rules and pull out an array of their names
        let localArray = [];
        let vm = this;
        _.forEach(vm.fields, function(thisField) {
          if (thisField.type == 'selection') {
            if (!!thisField['field-control']) {
              // There are fields to control - get the field references
              _.forEach(thisField['field-control'], function (thisRule) {
                localArray = _.union(localArray, thisRule['field-references']);
              })
            }
          }
        })
        return localArray;
      }      
    },
    methods: {
      calculateBalloonText: function() {
        let fullValue = this.interpolatedPrefix + this.linkValue + this.interpolatedSuffix;
        this.$emit('notifyInstanceEvent', {
          source: this.instance.id, 
          payload: {newValue: this.linkValue, fullValue: fullValue},
          type: 'updatedBalloonText'
          });
      },
      passthroughErrors: function (newErrors) {
        this.$emit('update:errors', newErrors);
      },
      toggle: function (e) {
        e.preventDefault();

        this.showInstance = !this.showInstance;
      },
      remove: function (e) {
        e.preventDefault();

        if (!this.disableRemove && window.confirm("Are you sure you want to remove " + this.interpolatedLabel + "?")) {
          this.$emit('destroy')
        }
      },
      dataUpdated: function (newData) {
        let instance = _.omit(this.instance, ['data'])
        instance.data = newData
        this.linkValue = this.getLinkValue(newData);
        this.$emit('update:instance', instance)
      },
      getLinkValue: function (instanceData) {
        let linkFieldReference = this.specification.link_field;
        // use the label generator to extract the link value from the field
        let newVal = this.interpolatedText('%{'+linkFieldReference+'}', instanceData);
        return newVal;
      },
      rollupState: function (fieldStyle) {
        let styleArray = _.split(fieldStyle.replace(/ /gi,""),",")
        let collapsedState = false;
        _.forEach(styleArray, function(styleEntry) {
          let thisEntry = _.split(styleEntry,":");
          if (thisEntry[0] == 'collapsed') {
            if (thisEntry[1] == 'true') {
              collapsedState = true
            }
          }
        });
        return collapsedState;
      }      
    }
  }
</script>

<style scoped lang="scss">
.emphasizenew {
  animation: fade 3s forwards;
  background-color: rgba(0,113,156,0.5);
}
@keyframes fade {
    to {background-color:transparent;}
}
</style>
